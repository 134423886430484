import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

//components
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";
import Loader from "../../components/Loader/Loader";

//services
import AuthService from "../../services/auth.service";

//styles
import "./style.scss";

export default function SigninPage({ redirect }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const emailHandler = (value) => {
        setEmail(value);
    };

    const passwordHandler = (value) => {
        setPassword(value);
    };

    const captchaHandler = (value) => {
        if (value) {
            return setIsCaptchaValid(true);
        }

        return setIsCaptchaValid(false);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        AuthService.login(email, password).then(
            () => {
                navigate(redirect || location?.state?.redirect || "/");
                navigate(0);
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    };

    return (
        <>
            <main className='container'>
                <div className='login'>
                    <div className='login-card'>
                        <div>
                            <h1 className='login-card-title'>Вхід в систему</h1>
                        </div>
                        <Input label='Електронна пошта' name='email' value={email} handler={emailHandler} />
                        <Input label='Пароль' type='password' name='password' value={password} handler={passwordHandler} />

                        <ButtonGroup position='center'>
                            <ReCAPTCHA sitekey='6LecyHspAAAAAHssfwag1_07ojfXDUEnp3uQvRWV' onChange={captchaHandler} />
                        </ButtonGroup>

                        <Button content='Увійти' handler={handleLogin} disabled={!(isCaptchaValid && email && password)} />

                        {message && (
                            <div className='form-group'>
                                <div className='login-card-form-group-alert' role='alert'>
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            {loading && <Loader />}
        </>
    );
}
