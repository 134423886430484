//components
import PageTitle from "../../components/PageTitle/PageTitle";

//services
import AuthService from "../../services/auth.service";

//styles
import "./style.scss";

//constants
const ROLES = {
    1: "Користувач",
    2: "Модератор",
    3: "Адміністратор",
};

const Profile = () => {
    const currentUser = AuthService.getCurrentUser();
    console.log(currentUser);

    return (
        <main className='container'>
            <div className='page profile-page'>
                <PageTitle pageTitle={"Профіль користувача"} />

                <div className='profile-page-card'>
                    <div className='profile-page-card-content'>
                        <div className='profile-page-card-content-img'>{/* <img src={userIcon} alt='user icon' /> */}</div>
                        <div className='profile-page-card-content-info'>
                            <div className='profile-page-card-content-info-username'>
                                <h3>
                                    {currentUser.username} <span>#{currentUser.id}</span>
                                </h3>
                            </div>
                            <div className='profile-page-card-content-info-email'>Email: {currentUser.email}</div>

                            <div className='profile-page-card-content-info-role'>Роль в системі: {ROLES[currentUser.roles.length]}</div>
                            <div className='profile-page-card-content-info-role'>Роль в проєкті: {currentUser.projectRoleTitle}</div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Profile;
