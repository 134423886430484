import './style.css';

export default function Loader() {
    return (
        <div className='loader'>
            <div className='loader-icon'>
            </div>
        </div>
    )
}
