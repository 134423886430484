import { useState, useEffect } from "react";
import axios from "axios";

//services
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

const useAxios = ({ url, method, body = null, headers = false, dependencies = [] }) => {
    const navigate = useNavigate();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setloading] = useState(true);

    const config = {
        method,
        url,
        ...(body && { data: body }),
        ...(headers && { headers: authHeader() }),
    };
    
    const fetchData = () => {
        axios(config)
            .then((response) => {
                setResponse(response.data);
            })
            .catch((error) => {
                setError(error);
                const status = error?.response?.status;
                if (status === 401) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else if (status === 403) {
                    navigate("/access-denied");
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            })
            .finally(() => {
                setloading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, dependencies);

    return { response, error, loading };
};

export default useAxios;
