import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//states
import { selectCurrentUser, selectAdminRole } from "../../features/authSlice";

//event bus
import eventBus from "../../common/EventBus";

//styles
import "./style.scss";

//images
import adminLogo from "../../assets/img/logos/admin-logo.svg";

//icons
import userIcon from "../../assets/img/illustrations/user.png";

export default function AdminHeader() {
    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);

    const logOutHandler = () => {
        eventBus.dispatch("logout");
    };

    return (
        <>
            {currentUser && (
                <header className='admin-header'>
                    <div className='container'>
                        <div className='admin-header-wrapper'>
                            <div className='admin-header-logo'>
                                <Link to='/applications'>
                                    <img src={adminLogo} alt='admin-logo' />
                                </Link>
                            </div>

                            <div className='admin-header-nav'>
                                <Link to='/'>Форма</Link>
                                <Link to='/check-applications'>Перевірка отримання</Link>
                                <Link to='/applications'>Анкети</Link>
                                <Link to='/dashboard'>Дашборд</Link>
                                {isAdmin && <Link to='/users'>Користувачі</Link>}
                            </div>
                            <div className='admin-header-login-control'>
                                <div className='header-login-control-icon'>
                                    <img src={userIcon} alt='User Icon' />
                                </div>
                                <Link to={"/profile"} className='admin-header-login-control-info'>
                                    <div>{currentUser.username}</div>
                                    <div>{currentUser.email}</div>
                                </Link>

                                <div className='admin-header-login-control-menu'>
                                    <Link to={"/profile"}>Профіль</Link>
                                    <a href='/signin' onClick={logOutHandler}>
                                        Вийти
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    );
}
