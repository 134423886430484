import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//states
import { selectAdminRole, selectCurrentUser } from "../../features/authSlice";

//event bus
import eventBus from "../../common/EventBus";

//styles
import "./style.scss";

//images
import adminLogo from "../../assets/img/logos/main-logo.png";

//icons
import userIcon from "../../assets/icons/user-icon.svg";
import menuIcom from "../../assets/icons/menu-burger-white.svg";

//constants
const NOVOSHKOLA_URL = "https://novoshkola.com.ua/";

export default function Header() {
    const location = useLocation();

    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const logOutHandler = () => {
        eventBus.dispatch("logout");
    };

    const mobileNavHandler = () => {
        setIsMobileNavOpen((prevState) => !prevState);
    };

    return (
        <>
            <header className='header'>
                {currentUser && (
                    <div className='header-admin-bar'>
                        <div className='header-admin-bar-nav'>
                            <Link to='/'>Форма</Link>
                            <Link to='/check-applications'>Перевірити отримання</Link>
                            <Link to='/applications'>Всі заявки</Link>
                            <Link to='/dashboard'>Дашборд</Link>
                            {isAdmin && <Link to='/users'>Користувачі</Link>}
                        </div>

                        <div className='header-admin-bar-control'>
                            <div className='header-admin-bar-control-icon'>
                                <img src={userIcon} alt='User Icon' />
                            </div>
                            <Link to={"/profile"} className='header-admin-bar-control-info'>
                                <div>{currentUser.username}</div>
                            </Link>

                            <div className='header-admin-bar-control-menu'>
                                <Link to={"/profile"}>Профіль</Link>
                                <a href='/signin' onClick={logOutHandler}>
                                    Вийти
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                <div className='container'>
                    <div className='header-wrapper'>
                        <div className='header-logo'>
                            <a href={NOVOSHKOLA_URL} target='_blank' rel='noreferrer'>
                                <img src={adminLogo} alt='admin-logo' />
                            </a>
                        </div>

                        <div className='header-nav-external'>
                            <a href={`${NOVOSHKOLA_URL}/philosophy`} target='_blank' rel='noreferrer'>
                                Про Школу
                            </a>
                            <a href={`${NOVOSHKOLA_URL}/enrolment`} target='_blank' rel='noreferrer'>
                                Вступ
                            </a>
                            <a href={`${NOVOSHKOLA_URL}/news`} target='_blank' rel='noreferrer'>
                                Новини
                            </a>
                        </div>

                        <div className='header-nav-internal'>
                            {location && location?.pathname === "/" ? (
                                <Link to='/check-applications'>Перевірити отримання</Link>
                            ) : (
                                <Link to='/'>Заповнити анкету</Link>
                            )}
                        </div>

                        <div className='header-mobile-control'>
                            <button onClick={mobileNavHandler}>
                                <img src={menuIcom} alt='menu icon' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mobile-nav' data-open={isMobileNavOpen}>
                    <div className='mobile-nav-wrapper'>
                        <div className=''></div>

                        <div className='mobile-nav-external'>
                            <a href={`${NOVOSHKOLA_URL}/philosophy`} target='_blank' rel='noreferrer'>
                                Про Школу
                            </a>
                            <a href={`${NOVOSHKOLA_URL}/enrolment`} target='_blank' rel='noreferrer'>
                                Вступ
                            </a>
                            <a href={`${NOVOSHKOLA_URL}/news`} target='_blank' rel='noreferrer'>
                                Новини
                            </a>
                        </div>
                        <div className='mobile-nav-internal'>
                            {location && location?.pathname === "/" ? (
                                <Link to='/check-applications' onClick={mobileNavHandler}>
                                    Перевірка анкети
                                </Link>
                            ) : (
                                <Link to='/' onClick={mobileNavHandler}>
                                    Заповнити анкету
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
