import { useState, useEffect } from "react";

//hooks
import useAxios from "../../hooks/useAxios";

//components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/UI/Button/Button";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";

//api
import API from "../../app/api";

//styles
import "./style.scss";

//constants
const PAGE_TITLE = "Користувачі";
const ROLES = {
    1: "Користувач",
    2: "Модератор",
    3: "Адміністратор",
};

export default function UsersPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const {
        response: usersData,
        loading,
        error: usersError,
    } = useAxios({
        method: "get",
        url: API.users.readAll,
        headers: true,
    });

    useEffect(() => {
        if (usersData !== null) {
            setUsers(usersData);
        }

        if (!loading) {
            setIsLoading(false);
        }
    }, [usersData, loading]);

    return (
        <>
            <main className='container'>
                <div className='page user-page'>
                    <PageTitle pageTitle={PAGE_TITLE} />

                    <ButtonGroup>
                        <Button content='Створити користувача' type='link' link='/signup' />
                    </ButtonGroup>

                    <table className='user-page-users-table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Ім'я</th>
                                <th>Пошта</th>
                                <th>Роль</th>
                                <th>Роль на проекті</th>
                                <th>Активний</th>
                                <th>Створено</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 &&
                                users.map((user) => {
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.username}</td>
                                            <td>{user.email}</td>
                                            <td>{ROLES[user.roles.length]}</td>
                                            <td>{user.project_role?.title || "Не вказано"}</td>
                                            <td>{user.active ? "Так" : "Ні"}</td>
                                            <td>{new Date(user.createdAt).toLocaleDateString("UK")}</td>
                                            <th>
                                                <Button content='Редагувати' type='link' link={"/user/" + user.id} />
                                            </th>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </main>
            {isLoading && <Loader />}
        </>
    );
}
