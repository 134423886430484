import React, { useState } from "react";

//styles
import "./style.scss";

const Textarea = ({ label, value, handler, maxLength, height = "small" }) => {
    const [isFocused, setIsFocused] = useState(false);

    const focus = (e) => {
        setIsFocused(true);
    };

    const blur = (e) => {
        setIsFocused(false);
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        if (!maxLength || inputValue.length <= maxLength) {
            handler(inputValue);
        }
    };

    return (
        <div className={`textarea textarea-${height} ${isFocused ? "textarea-focused" : ""}`}>
            <label className='textarea-label'>
                <div>
                    <p>{label}</p>
                    {maxLength && (
                        <span className='textarea-limit-counter'>
                            {maxLength - value.length}/{maxLength}
                        </span>
                    )}
                </div>
                <textarea className='textarea-input' value={value} onChange={handleChange} onFocus={focus} onBlur={blur} maxLength={maxLength} />
            </label>
        </div>
    );
};

export default Textarea;
