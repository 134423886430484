import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import Checkbox from "../UI/Checkbox/Checkbox";
import Textarea from "../UI/Textarea/Textarea";
import InputDate from "../UI/InputDate/InputDate";
import ButtonGroup from "../UI/ButtonGroup/ButtonGroup";
import Button from "../UI/client/Button/Button";

//styles
import "./style.scss";

//api
import API from "../../app/api";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../UI/Modal/Modal";

//constants
const FIELD_LABLES = {
    name: "Вкажіть Ваше прізвище, ім’я та по батькові",
    birthdayDate: "Вкажіть Вашу дату народження",
    informationSource: "Звідки Ви дізнались про Стипендіальну програму",
    phoneNumber: "Вкажіть Ваш номер мобільного телефону (+380502222222) або Telegram (@stypendiat)",
    email: "Вкажіть, будь ласка, Вашу електронну пошту",
    futureGrade: "У якому класі Ви будете навчатись у наступному 2024/2025 навчальному році?",
    educationalInstitution: "У якому навчальному закладі Ви навчаєтесь у 2023/2024 навчальному році (зараз)?",
    settlement: "З якого Ви міста/села та області?",
    socialMediaLink: "Надайте, будь ласка, посилання на Ваш профіль у соціальних мережах (Facebook, Instagram, TikTok тощо)",
    photoLink: "Надайте посилання на Ваше фото (попередньо перевірте, будь ласка, налаштування доступу до файлу)",
    academicAchievements:
        "Якщо Ви переможець/переможниця в предметних олімпіадах, конкурсах МАН, маєте публікації або винаходи тощо, вкажіть, будь ласка",
    lastYearReportCardPhotoLink: "Надайте посилання на фото Вашого табеля минулого навчального року",
    currentYearFirstSemesterReportCardPhotoLink: "Надайте посилання на фото Вашого табеля 1 семестру цього навчального року",
    sportsAndCreativeAchievements:
        "Якщо Ви професійно займаєтесь певним видом спорту або маєте кваліфікацію у певному виді творчості — розкажіть про це, будь ласка. Поділіться своїми творчими та/або спортивними досягненнями, якими Ви пишаєтеся найбільше",
    socialActivity:
        "Якщо Ви брали участь у соціальній діяльності, у благодійних акціях, чи активну участь у шкільному житті або волонтерили, розкажіть, будь ласка, про це",
    motivation: "Чому Ви хочете отримати грант на навчання саме у Новопечерській школі?",
    videoLink: "Надайте, будь ласка, посилання на відео",
    aboutFamily: "Розкажіть про Вашу сім'ю (як ви проводите час разом, чи маєте особливі сімейні традиції тощо?)",
    aboutApproachingVictory: "Що Ви разом спільно з родиною робите для наближення нашої Перемоги?",
    contactPersonName: "ПІБ одного з батьків або контактної особи",
    contactPersonEmail: "Електронна пошта одного з батьків або контактної особи",
    contactPersonPhone: "Номер телефону одного з батьків або контактної особи",
    parentsSocialMediaLink: "Поділіться, будь ласка, посиланнями на профілі батьків або контактної особи у соціальних мережах",
    essay: "У вільній формі опишіть, будь ласка, Ваш досвід командної роботи та розкрийте важливість об’єднання зусиль для досягнення кращих результатів. Ми гарантуємо конфіденційність усієї наданої інформації. Орієнтуйтесь на обсяг до 3000 символів.",
    isConfirmed: "Я підтверджую свою фізичну присутність на етапі проходження конкурсних випробувань.",
    isAllowedDataProcessing: "Я даю згоду на обробку персональних даних.",
    isAcceptedTerms:
        "Я усвідомлюю, що  Стипендіальна програма - це соціальний проєкт для здібних дітей з родин з рівнем достатку низьким або нижче середнього, а також підтримка родинам, що опинились в складних життєвих обставинах внаслідок повномасштабного воєнного вторгнення. Ви підтверджуєте, що Ваша родина відповідає одному з критеріїв та готові надати, за необхідності, підтверджуючі документи.",
};
const INFORMATION_SOURCE_OPTIONS = ["Facebook", "Сайт Новопечерської школи", "Освіторія Медіа", "YouTube", "Telegram", "Instagram", "Інше"];
const FUTURE_GRADE_OPTIONS = [8, 9, 10, 11];

const FormNone = ({ content }) => {
    return <div className='form-note'>{content}</div>;
};

const ApplicationForm = () => {
    const navigate = useNavigate();

    const [isApplicationValid, setIsApplicationValid] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [name, setName] = useState("");
    const [birthdayDate, setbirthdayDate] = useState(new Date(2009, 0, 1, 0, 0, 0));
    const [informationSource, setInformationSource] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(null);

    const [futureGrade, setFutureGrade] = useState("");
    const [educationalInstitution, setEducationalInstitution] = useState("");
    const [settlement, setSettlement] = useState("");

    const [socialMediaLink, setSocialMediaLink] = useState("");
    const [photoLink, setPhotoLink] = useState("");

    const [academicAchievements, setAcademicAchievements] = useState("");
    const [lastYearReportCardPhotoLink, setLastYearReportCardPhotoLink] = useState("");
    const [currentYearFirstSemesterReportCardPhotoLink, setCurrentYearFirstSemesterReportCardPhotoLink] = useState("");

    const [sportsAndCreativeAchievements, setSportsAndCreativeAchievements] = useState("");
    const [socialActivity, setSocialActivity] = useState("");
    const [motivation, setMotivation] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [aboutFamily, setAboutFamily] = useState("");
    const [aboutApproachingVictory, setAboutApproachingVictory] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [contactPersonEmail, setContactPersonEmail] = useState("");
    const [contactPersonPhone, setContactPersonPhone] = useState("");
    const [parentsSocialMediaLink, setParentsSocialMediaLink] = useState("");
    const [essay, setEssay] = useState("");

    const [isConfirmed, setIsConfirmed] = useState(true);
    const [isAllowedDataProcessing, setIsAllowedDataProcessing] = useState(true);
    const [isAcceptedTerms, setIsAcceptedTerms] = useState(true);

    const nameHandler = (value) => {
        setName(value);
    };

    const birthdayDateHandler = (value) => {
        setbirthdayDate(value);
    };

    const phoneNumberHandler = (value) => {
        setPhoneNumber(value);
    };

    const emailHandler = (value) => {
        setEmail(value);
        emailValidationHandler(value, "input");
    };

    const emailFocusHandler = (value) => {
        setEmailValidationMsg(null);
    };

    const emailBlurHandler = (value) => {
        emailValidationHandler(value, "blur");
    };

    const emailValidationHandler = (value, type) => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "i"
        );
        if (!reg.test(value)) {
            setEmailValidation(false);
            type !== "input" && setEmailValidationMsg("Вказано некоректний email.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(null);
        }
    };

    const InformationSourceHandler = (value) => {
        setInformationSource(value);
    };

    const futureGradeHandler = (value) => {
        setFutureGrade(value);
    };

    const educationalInstitutionHandler = (value) => {
        setEducationalInstitution(value);
    };

    const settlementHandler = (value) => {
        setSettlement(value);
    };

    const socialMediaLinkHandler = (value) => {
        setSocialMediaLink(value);
    };

    const photoLinkHandler = (value) => {
        setPhotoLink(value);
    };

    const academicAchievementsHandler = (value) => {
        setAcademicAchievements(value);
    };

    const lastYearReportCardPhotoLinkHandler = (value) => {
        setLastYearReportCardPhotoLink(value);
    };

    const currentYearFirstSemesterReportCardPhotoLinkHandler = (value) => {
        setCurrentYearFirstSemesterReportCardPhotoLink(value);
    };

    const sportsAndCreativeAchievementsHandler = (value) => {
        setSportsAndCreativeAchievements(value);
    };

    const socialActivityHandler = (value) => {
        setSocialActivity(value);
    };

    const motivationHandler = (value) => {
        setMotivation(value);
    };

    const videoLinkHandler = (value) => {
        setVideoLink(value);
    };

    const aboutFamilyHandler = (value) => {
        setAboutFamily(value);
    };

    const aboutApproachingVictoryHandler = (value) => {
        setAboutApproachingVictory(value);
    };

    const contactPersonNameHandler = (value) => {
        setContactPersonName(value);
    };

    const contactPersonEmailHandler = (value) => {
        setContactPersonEmail(value);
    };

    const contactPersonPhoneHandler = (value) => {
        setContactPersonPhone(value);
    };

    const parentsSocialMediaLinkHandler = (value) => {
        setParentsSocialMediaLink(value);
    };

    const essayHandler = (value) => {
        setEssay(value);
    };

    const isConfirmedHandler = () => {
        setIsConfirmed((prevValue) => !prevValue);
    };

    const isAllowedDataProcessingHandler = () => {
        setIsAllowedDataProcessing((prevValue) => !prevValue);
    };

    const isAcceptedTermsHandler = () => {
        setIsAcceptedTerms((prevValue) => !prevValue);
    };

    useEffect(() => {
        if (
            name &&
            birthdayDate &&
            informationSource &&
            phoneNumber &&
            email &&
            emailValidation &&
            futureGrade &&
            educationalInstitution &&
            settlement &&
            socialMediaLink &&
            photoLink &&
            academicAchievements &&
            lastYearReportCardPhotoLink &&
            currentYearFirstSemesterReportCardPhotoLink &&
            sportsAndCreativeAchievements &&
            socialActivity &&
            motivation &&
            videoLink &&
            aboutFamily &&
            aboutApproachingVictory &&
            contactPersonName &&
            contactPersonEmail &&
            contactPersonPhone &&
            parentsSocialMediaLink &&
            essay &&
            isConfirmed &&
            isAllowedDataProcessing &&
            isAcceptedTerms &&
            isCaptchaValid
        ) {
            setIsApplicationValid(true);
        } else {
            setIsApplicationValid(false);
        }
    }, [
        name,
        birthdayDate,
        informationSource,
        phoneNumber,
        email,
        emailValidation,
        futureGrade,
        educationalInstitution,
        settlement,
        socialMediaLink,
        photoLink,
        academicAchievements,
        lastYearReportCardPhotoLink,
        currentYearFirstSemesterReportCardPhotoLink,
        sportsAndCreativeAchievements,
        socialActivity,
        motivation,
        videoLink,
        aboutFamily,
        aboutApproachingVictory,
        contactPersonName,
        contactPersonEmail,
        contactPersonPhone,
        parentsSocialMediaLink,
        essay,
        isConfirmed,
        isAllowedDataProcessing,
        isAcceptedTerms,
        isCaptchaValid,
    ]);

    const sendApplication = () => {
        axios
            .post(API.application.create, {
                name,
                birthdayDate,
                informationSource,
                phoneNumber,
                email,
                futureGrade,
                educationalInstitution,
                settlement,
                socialMediaLink,
                photoLink,
                academicAchievements,
                lastYearReportCardPhotoLink,
                currentYearFirstSemesterReportCardPhotoLink,
                sportsAndCreativeAchievements,
                socialActivity,
                motivation,
                videoLink,
                aboutFamily,
                aboutApproachingVictory,
                contactPersonName,
                contactPersonEmail,
                contactPersonPhone,
                parentsSocialMediaLink,
                essay,
                isConfirmed,
                isAllowedDataProcessing,
                isAcceptedTerms,
            })
            .then(() => {
                setIsModalOpen(true);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    };

    const onChangeCaptcha = (value) => {
        if (value) {
            return setIsCaptchaValid(true);
        }

        return setIsCaptchaValid(false);
    };

    const closeModalHandler = () => {
        navigate("/");
        navigate(0);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div id='application' className='applicant-form'>
                <h2 className='applicant-form-title'>Подання заявки</h2>

                <FormNone content='Важливо! Учні Новопечерської школи не можуть подаватися на програму.' />

                <Input label={FIELD_LABLES.name} value={name} handler={nameHandler} required={true} maxLength={200} />

                <InputDate label={FIELD_LABLES.birthdayDate} value={birthdayDate} handler={birthdayDateHandler} required={true} />

                <Select
                    label={FIELD_LABLES.informationSource}
                    options={INFORMATION_SOURCE_OPTIONS}
                    value={informationSource}
                    handler={InformationSourceHandler}
                    required={true}
                />

                <Input label={FIELD_LABLES.phoneNumber} value={phoneNumber} handler={phoneNumberHandler} required={true} maxLength={200} />
                <Input
                    label={FIELD_LABLES.email}
                    value={email}
                    handler={emailHandler}
                    focusHandler={emailFocusHandler}
                    blurHandler={emailBlurHandler}
                    validationMsg={emailValidationMsg}
                    required={true}
                    maxLength={200}
                />

                <Select
                    label={FIELD_LABLES.futureGrade}
                    options={FUTURE_GRADE_OPTIONS}
                    value={futureGrade}
                    handler={futureGradeHandler}
                    required={true}
                />

                <Input
                    label={FIELD_LABLES.educationalInstitution}
                    value={educationalInstitution}
                    handler={educationalInstitutionHandler}
                    required={true}
                    maxLength={200}
                />

                <Input label={FIELD_LABLES.settlement} value={settlement} handler={settlementHandler} required={true} maxLength={200} />

                <Input
                    label={FIELD_LABLES.socialMediaLink}
                    value={socialMediaLink}
                    handler={socialMediaLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Input label={FIELD_LABLES.photoLink} value={photoLink} handler={photoLinkHandler} required={true} maxLength={600} />

                <FormNone content='Розкажіть про себе. Не соромтесь і розповідайте про свої здобутки. Нам важливо зрозуміти, чим саме Ви цікавитесь та яких важливих результатів досягли у кожній сфері.' />

                <Textarea
                    label={FIELD_LABLES.academicAchievements}
                    value={academicAchievements}
                    handler={academicAchievementsHandler}
                    maxLength={3000}
                />

                <Input
                    label={FIELD_LABLES.lastYearReportCardPhotoLink}
                    value={lastYearReportCardPhotoLink}
                    handler={lastYearReportCardPhotoLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Input
                    label={FIELD_LABLES.currentYearFirstSemesterReportCardPhotoLink}
                    value={currentYearFirstSemesterReportCardPhotoLink}
                    handler={currentYearFirstSemesterReportCardPhotoLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Textarea
                    label={FIELD_LABLES.sportsAndCreativeAchievements}
                    value={sportsAndCreativeAchievements}
                    handler={sportsAndCreativeAchievementsHandler}
                    maxLength={3000}
                />

                <Textarea label={FIELD_LABLES.socialActivity} value={socialActivity} handler={socialActivityHandler} maxLength={3000} />

                <Textarea label={FIELD_LABLES.motivation} value={motivation} handler={motivationHandler} required={true} maxLength={3000} />

                <FormNone content="Нас також цікавлять Ваші досягнення та інтереси. Інколи їх складно передати словами, тому Ви можете підготувати коротке відео про себе. Розкажіть, хто Ви, що любите, як проводите вільний час, про що мрієте – все, що Ви хочете, щоб про Вас знали. Тривалість – від 1 до 2 хв. Будь ласка, дотримуйтесь цього часового проміжку. Відео залийте на youtube чи інший простір, де можна буде його переглянути за наданим доступним посиланням. Пам'ятайте про безпеку в інтернеті!" />

                <Input label={FIELD_LABLES.videoLink} value={videoLink} handler={videoLinkHandler} required={true} maxLength={600} />

                <FormNone content='Коли ми приймаємо учня/ученицю до школи, ми також приймаємо і родину до великої спільноти Новопечерської школи. Разом ми виховуємо лідерів, майбутніх змінотворців України. Будемо раді дізнатися трохи більше про Ваших близьких та про Ваші лідерські якості.' />

                <Textarea label={FIELD_LABLES.aboutFamily} value={aboutFamily} handler={aboutFamilyHandler} maxLength={3000} />

                <Textarea
                    label={FIELD_LABLES.aboutApproachingVictory}
                    value={aboutApproachingVictory}
                    handler={aboutApproachingVictoryHandler}
                    maxLength={3000}
                />

                <Input
                    label={FIELD_LABLES.contactPersonName}
                    value={contactPersonName}
                    handler={contactPersonNameHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FIELD_LABLES.contactPersonEmail}
                    value={contactPersonEmail}
                    handler={contactPersonEmailHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FIELD_LABLES.contactPersonPhone}
                    value={contactPersonPhone}
                    handler={contactPersonPhoneHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FIELD_LABLES.parentsSocialMediaLink}
                    value={parentsSocialMediaLink}
                    handler={parentsSocialMediaLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Textarea label={FIELD_LABLES.essay} value={essay} handler={essayHandler} maxLength={3000} height='big' />

                <Checkbox label={FIELD_LABLES.isConfirmed} value={isConfirmed} handler={isConfirmedHandler} />

                <Checkbox label={FIELD_LABLES.isAllowedDataProcessing} value={isAllowedDataProcessing} handler={isAllowedDataProcessingHandler} />

                <Checkbox label={FIELD_LABLES.isAcceptedTerms} value={isAcceptedTerms} handler={isAcceptedTermsHandler} />

                <ButtonGroup position='center'>
                    <ReCAPTCHA sitekey='6LecyHspAAAAAHssfwag1_07ojfXDUEnp3uQvRWV' onChange={onChangeCaptcha} />
                </ButtonGroup>

                <ButtonGroup>
                    <Button content='Надіслати' handler={sendApplication} disabled={!isApplicationValid} />
                </ButtonGroup>
            </div>
            {isModalOpen && (
                <Modal
                    title='Дякуємо!'
                    message='Ваша заявка отримана'
                    handler={closeModalHandler}
                    buttonText='Закрити'
                    closeHandler={closeModalHandler}
                />
            )}
        </>
    );
};

export default ApplicationForm;
