import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";
import Button from "../../components/UI/Button/Button";
import PageSubtitle from "../../components/UI/PageSubtitle/PageSubtitle";

const PAGE_TITLE = "Доступ заборонено";

const AccessDeniedPage = () => {
    return (
        <main className='container'>
            <div className='page access-denied-page'>
                <PageTitle pageTitle={PAGE_TITLE} />
                <PageSubtitle pageSubtitle='Ви не маєте права переглядати цю сторінку.' />

                <ButtonGroup position="center">
                    <Button content='На головну' type='link' link='/' />
                </ButtonGroup>
            </div>
        </main>
    );
};

export default AccessDeniedPage;
