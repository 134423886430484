import { useState } from "react";

//components
import PageMetadata from "../../components/PageMetadata/PageMetadata";
import ApplicationForm from "../../components/ApplicationForm/ApplicationForm";
import LandingInformation from "../../components/LandingInformation/LandingInformation";

//styles
import "./style.scss";

//constants
const PAGE_TITLE = "Стипендіальна програма від ГC «Освіторія» та Новопечерської школи";
const PAGE_DESCRIPTION = "Стипендіальна програма — це грант від громадської спілки «Освіторія» та Новопечерської школи на безоплатне навчання";
const endDate = 1713560400000;

export default function HomePage() {
    const [nowDate, setnowDate] = useState(Date.now());

    return (
        <>
            <PageMetadata title={PAGE_TITLE} descrption={PAGE_DESCRIPTION} />

            <main className='container'>
                <div className='home-page page'>
                    <LandingInformation nowDate={nowDate} endDate={endDate} />
                    {nowDate < endDate && <ApplicationForm />}
                </div>
            </main>
        </>
    );
}
