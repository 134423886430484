import { Link } from "react-router-dom";
import "./style.scss";

export default function Button({ handler, content, disabled = false, highlighted = true, type = "button", link, target = "_blank" }) {
    if (type === "button") {
        return (
            <button className='button' onClick={handler} disabled={disabled} data-highlighted={highlighted}>
                {content}
            </button>
        );
    } else if (type === "link") {
        return (
            <Link className='button' to={link} disabled={disabled} data-highlighted={highlighted}>
                {content}
            </Link>
        );
    } else if (type === "internal-link") {
        return (
            <a className='button' href={link} disabled={disabled} data-highlighted={highlighted} target={target}>
                {content}
            </a>
        );
    }
}
