import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//componets
import StageTitle from "./StageTitle";
import StageSubTitle from "./StageSubTitle";
import Button from "../../UI/Button/Button";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import Input from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";
import Textarea from "../../UI/Textarea/Textarea";

//api
import API from "../../../app/api";

//constants
import ASSESSMENTS from "../../../constans/applicationAssessments";
import AuthService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

const StageThreeAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const allowedTeacherEditorRoles = ["project manager", "school teacher"];
    const allowedTeacherViewerRoles = [
        "project manager",
        "project director",
        "school director",
        "school director deputy",
        "school teacher",
        "school psychologist",
        "school manager",
    ];

    const allowedPsychologistEditorRoles = ["project manager", "school psychologist"];
    const allowedPsychologistViewerRoles = [
        "project manager",
        "project director",
        "school director",
        "school director deputy",
        "school teacher",
        "school psychologist",
        "school manager",
    ];

    const mathPoints = applicationAssessment?.stageThreeMathPoints;
    const ukrainianSpeakingPoints = applicationAssessment?.stageThreeUkrainianSpeakingPoints;
    const ukrainianWritingPoints = applicationAssessment?.stageThreeUkrainianWritingPoints;
    const englishSpeakingPoints = applicationAssessment?.stageThreeEnglishSpeakingPoints;
    const englishWritingPoints = applicationAssessment?.stageThreeEnglishWritingPoints;
    const teacherAssessment = applicationAssessment?.stageThreeTeacherAssessment;
    const psychologistComment = applicationAssessment?.stageThreePsychologistComment;
    const psychologistAssessment = applicationAssessment?.stageThreePsychologistAssessment;

    const [stageThreeMathPoints, setStageThreeMathPoints] = useState(mathPoints || "");
    const [stageThreeUkrainianSpeakingPoints, setStageThreeUkrainianSpeakingPoints] = useState(ukrainianSpeakingPoints || "");
    const [stageThreeUkrainianWritingPoints, setStageThreeUkrainianWritingPoints] = useState(ukrainianWritingPoints || "");
    const [stageThreeEnglishSpeakingPoints, setStageThreeEnglishSpeakingPoints] = useState(englishSpeakingPoints || "");
    const [stageThreeEnglishWritingPoints, setStageThreeEnglishWritingPoints] = useState(englishWritingPoints || "");
    const [stageThreeTeacherAssessment, setStageThreeTeacherAssessment] = useState(teacherAssessment || "");

    const [stageThreePsychologistComment, setStageThreePsychologistComment] = useState(psychologistComment || "");
    const [stageThreePsychologistAssessment, setStageThreePsychologistAssessment] = useState(psychologistAssessment || "");

    const pointsValidator = (value, setPoints) => {
        const onlyNumbersRegExp = new RegExp("^[0-9]+$");
        const isValid = +value >= 1 && +value <= 12;

        if (onlyNumbersRegExp.test(value) && isValid) {
            setPoints(value);
        } else if (!value) {
            setPoints("");
        }
    };

    const stageThreeMathPointsHandler = (value) => {
        pointsValidator(value, setStageThreeMathPoints);
    };

    const stageThreeUkrainianSpeakingPointsHandler = (value) => {
        pointsValidator(value, setStageThreeUkrainianSpeakingPoints);
    };

    const stageThreeUkrainianWritingPointsHandler = (value) => {
        pointsValidator(value, setStageThreeUkrainianWritingPoints);
    };

    const stageThreeEnglishSpeakingPointsHandler = (value) => {
        pointsValidator(value, setStageThreeEnglishSpeakingPoints);
    };

    const stageThreeEnglishWritingPointsHandler = (value) => {
        pointsValidator(value, setStageThreeEnglishWritingPoints);
    };

    const stageThreeTeacherAssessmentHandler = (value) => {
        setStageThreeTeacherAssessment(value);
    };

    const stageThreePsychologistCommentHandler = (value) => {
        setStageThreePsychologistComment(value);
    };

    const stageThreePsychologistAssessmentHandler = (value) => {
        setStageThreePsychologistAssessment(value);
    };

    const updateStageThreeTeacher = () => {
        axios
            .post(
                API.assessment.updateStageThreeTeacherAssessment,
                {
                    id,
                    stageThreeMathPoints,
                    stageThreeUkrainianSpeakingPoints,
                    stageThreeUkrainianWritingPoints,
                    stageThreeEnglishSpeakingPoints,
                    stageThreeEnglishWritingPoints,
                    stageThreeTeacherAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateStageThreePsychologist = () => {
        axios
            .post(
                API.assessment.updateStageThreePsychologistAssessment,
                {
                    id,
                    stageThreePsychologistComment,
                    stageThreePsychologistAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    if (applicationAssessment?.stageTwoAssessment === "рекомендовано") {
        return (
            <div className='stage'>
                <StageTitle title='Етап 3. Оцінки тестування та психолога' />

                <StageSubTitle subTitle='Оцінки тестування' />

                {allowedTeacherViewerRoles.includes(userRole) ? (
                    teacherAssessment ? (
                        <div className='stage-rated' data-assessment={teacherAssessment}>
                            <p className='stage-rated-points'>
                                {(englishWritingPoints + englishSpeakingPoints + ukrainianWritingPoints + ukrainianSpeakingPoints + mathPoints) / 5}
                            </p>
                            <p className='stage-rated-point'>Математика: {mathPoints}</p>
                            <p className='stage-rated-point'>Українська мова (усна): {englishSpeakingPoints}</p>
                            <p className='stage-rated-point'>Українська мова (письмова): {englishWritingPoints}</p>
                            <p className='stage-rated-point'>Англійська мова (усна): {ukrainianSpeakingPoints}</p>
                            <p className='stage-rated-point'>Англійська мова (письмова): {ukrainianWritingPoints}</p>
                            <p className='stage-rated-assessment'>{teacherAssessment}</p>
                        </div>
                    ) : (
                        <div className='stage-not-rated'>
                            <p>Не оцінено</p>
                        </div>
                    )
                ) : (
                    <p>У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}

                {allowedTeacherEditorRoles.includes(userRole) && (
                    <div className='stage-form'>
                        <Input label='Кількість балів за Математику' value={stageThreeMathPoints} handler={stageThreeMathPointsHandler} />
                        <Input
                            label='Кількість балів за Українську мову (усна)'
                            value={stageThreeUkrainianSpeakingPoints}
                            handler={stageThreeUkrainianSpeakingPointsHandler}
                        />
                        <Input
                            label='Кількість балів за Українську мову (письмова)'
                            value={stageThreeUkrainianWritingPoints}
                            handler={stageThreeUkrainianWritingPointsHandler}
                        />

                        <Input
                            label='Кількість балів за Англійську мову (усна)'
                            value={stageThreeEnglishSpeakingPoints}
                            handler={stageThreeEnglishSpeakingPointsHandler}
                        />
                        <Input
                            label='Кількість балів за Англійську мову (письмова)'
                            value={stageThreeEnglishWritingPoints}
                            handler={stageThreeEnglishWritingPointsHandler}
                        />

                        <Select
                            label='Фінальна оцінка'
                            options={ASSESSMENTS}
                            value={stageThreeTeacherAssessment}
                            handler={stageThreeTeacherAssessmentHandler}
                        />

                        <ButtonGroup>
                            <Button
                                content='Зберегти'
                                handler={updateStageThreeTeacher}
                                disabled={
                                    !(
                                        stageThreeMathPoints &&
                                        stageThreeUkrainianSpeakingPoints &&
                                        stageThreeUkrainianWritingPoints &&
                                        stageThreeEnglishSpeakingPoints &&
                                        stageThreeEnglishWritingPoints &&
                                        stageThreeTeacherAssessment
                                    )
                                }
                            />
                        </ButtonGroup>
                    </div>
                )}

                <StageSubTitle subTitle='Оцінка психолога' />

                {allowedPsychologistViewerRoles.includes(userRole) ? (
                    psychologistAssessment ? (
                        <div className='stage-rated' data-assessment={psychologistAssessment}>
                            <p className='stage-rated-assessment'>{psychologistAssessment}</p>
                            <p className='stage-rated-comment'>Коментар: {psychologistComment}</p>
                        </div>
                    ) : (
                        <div className='stage-not-rated'>
                            <p>Не оцінено</p>
                        </div>
                    )
                ) : (
                    <p className='stage-note'>У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}

                {allowedPsychologistEditorRoles.includes(userRole) && (
                    <div className='stage-form'>
                        <Textarea
                            label='Коментар психолога'
                            value={stageThreePsychologistComment}
                            handler={stageThreePsychologistCommentHandler}
                        />

                        <Select
                            label='Фінальна оцінка психолога'
                            options={ASSESSMENTS}
                            value={stageThreePsychologistAssessment}
                            handler={stageThreePsychologistAssessmentHandler}
                        />

                        <ButtonGroup>
                            <Button
                                content='Зберегти'
                                handler={updateStageThreePsychologist}
                                disabled={!(stageThreePsychologistComment && stageThreePsychologistAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className='stage'>
            <StageTitle title='Етап 3. Оцінки тестування та психолога' />
            <p className='stage-note'>Анкета не отримала рекомендації на попередньому етапі.</p>
        </div>
    );
};

export default StageThreeAssessment;
