import { useLocation } from "react-router-dom";

//styles
import "./style.scss";

//images
import serverImage from "../../assets/img/other/server.jpg";

const ErrorPage = () => {
    const location = useLocation();

    return (
        <main className='error-page'>
            <div className='container'>
                <div className='row'>
                    <h1>Упс... </h1>
                    <h2>Виникла помилка :(</h2>
                </div>
                {location.state?.error ? (
                    <>
                        <div className='row'>
                            <h3>Будь ласка, передайте це повідомлення адміністратору сайту:</h3>
                            <div className='error-page-alert'>{location.state?.error}</div>
                        </div>
                    </>
                ) : (
                    <div className='row'>
                        <h3>Будь ласка, зверніться до адміністратора сайту.</h3>
                    </div>
                )}
            </div>
            <div className='error-page-bg'>
                <img src={serverImage} alt='server' />
            </div>
        </main>
    );
};

export default ErrorPage;
