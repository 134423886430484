import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

//hooks
import useAxios from "../../hooks/useAxios";

//components
import PageTitle from "../../components/PageTitle/PageTitle";
import PageSubtitle from "../../components/UI/PageSubtitle/PageSubtitle";
import Input from "../../components/UI/Input/Input";
import Select from "../../components/UI/Select/Select";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";
import Button from "../../components/UI/Button/Button";
import Loader from "../../components/Loader/Loader";

//api
import API from "../../app/api";

//services
import AuthService from "../../services/auth.service";
import authHeader from "../../services/auth-header";

//styles
import "./style.scss";

//constants
const PAGE_TITLE = "Управління користувачем";

const ROLES = {
    1: "Користувач",
    2: "Модератор",
    3: "Адміністратор",
};

export default function UserPage() {
    const { userId } = useParams();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState({
        id: userId,
        email: "",
        username: "",
        roles: [],
    });

    const [roles, setRoles] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [rolesIds, setRolesIds] = useState([]);

    const [userProjectRole, setUserProjectRole] = useState("");
    const [userProjectRoleId, setUserProjectRoleId] = useState(undefined);

    const [userPassword, setUserPassword] = useState("");
    const [userPasswordValidation, setUserPasswordValidation] = useState(false);

    const {
        response: userData,
        loading,
        error,
    } = useAxios({
        method: "post",
        url: API.users.readOne,
        body: { id: userId },
        headers: true,
    });

    useEffect(() => {
        if (userData !== null) {
            setUser(userData);
            setUserProjectRole(userData.project_role?.title);
            setUserProjectRoleId(userData.project_role?.id);
        }

        if (!loading) {
            setIsLoading(false);
        }
    }, [userData]);

    const {
        response: usersData,
        loading: usersLoading,
        error: usersError,
    } = useAxios({
        method: "get",
        url: API.projectRoles.readAll,
        headers: true,
    });

    useEffect(() => {
        if (usersData !== null) {
            setRoles(usersData);
            setRolesOptions(usersData.map((projectRole) => projectRole.title));
            const ids = {};
            usersData.map((projectRole) => (ids[projectRole.title] = projectRole.id));
            setRolesIds(ids);
            setIsLoading(false);
        }

        if (!loading) {
            setIsLoading(false);
        }
    }, [usersData]);

    const usernameHandler = (value) => {
        setUser((prev) => {
            let cur = { ...prev };
            cur.username = value;
            return cur;
        });
    };

    const userPasswordHandler = (value) => {
        setUserPassword(value);
        if (value.length > 12) {
            setUserPasswordValidation(true);
        } else {
            setUserPasswordValidation(false);
        }
    };

    const updatePassword = () => {
        setIsLoading(true);
        axios
            .post(API.users.updatePassword, { id: userId, password: userPassword }, { headers: authHeader() })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const userProjectRoleHanler = (value) => {
        setUserProjectRole(value);
        setUserProjectRoleId(rolesIds[value]);
    };

    const updateProjectRole = () => {
        axios
            .post(API.users.updateUserProjectRole, { id: userId, projectRoleId: userProjectRoleId }, { headers: authHeader() })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>

            <main className='container'>
                <div className='page user-page'>
                    <PageTitle pageTitle={PAGE_TITLE} />

                    <PageSubtitle pageSubtitle='Налаштування' />

                    <div className='user-page-form'>
                        <Input label='Email' value={user.email} disabled />
                        <Input label="Ім'я користувача" value={user.username} handler={usernameHandler} disabled={true} />

                        <Input label='Роль' value={ROLES[user.roles.length]} disabled={true} />

                        <Input label='Новий пароль' value={userPassword} handler={userPasswordHandler} />
                        <ButtonGroup>
                            <Button content='Оновити пароль' handler={updatePassword} disabled={!userPasswordValidation} />
                        </ButtonGroup>
                    </div>

                    <PageSubtitle pageSubtitle='Роль на проєкті' />

                    <div className='user-page-form'>
                        <Select label='Роль' handler={userProjectRoleHanler} value={userProjectRole} options={rolesOptions} />

                        <ButtonGroup>
                            <Button content='Оновити роль на проєкті' handler={updateProjectRole} />
                        </ButtonGroup>
                    </div>
                </div>
            </main>
            {isLoading && <Loader />}
        </>
    );
}
