import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components
import Input from "../../components/UI/Input/Input";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";
import Button from "../../components/UI/client/Button/Button";
import ComponentLoader from "../../components/UI/ComponentLoader/ComponentLoader";

//api
import API from "../../app/api";

//styles
import "./style.scss";
import ReCAPTCHA from "react-google-recaptcha";
import parseDate from "../../features/parseDate";

const CheckApplicationPage = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(null);

    const [results, setResults] = useState();

    const emailHandler = (value) => {
        setEmail(value);
        emailValidationHandler(value, "input");
    };

    const emailFocusHandler = (value) => {
        setEmailValidationMsg(null);
    };

    const emailBlurHandler = (value) => {
        emailValidationHandler(value, "blur");
    };

    const emailValidationHandler = (value, type) => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "i"
        );
        if (!reg.test(value)) {
            setEmailValidation(false);
            type !== "input" && setEmailValidationMsg("Вказано некоректний email.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(null);
        }
    };

    const checkApplication = () => {
        axios
            .post(API.application.checkOneByEmail, { email })
            .then((response) => {
                console.log(response);
                setResults(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    };

    const onChangeCaptcha = (value) => {
        if (value) {
            return setIsCaptchaValid(true);
        }

        return setIsCaptchaValid(false);
    };

    return (
        <main className='container'>
            <div className='page check-applications-page'>
                <h1>Перевірка отримання анкети</h1>

                <p>
                    На цій сторінці ви можете перевірити, чи отримали ми Вашу анкету. Для цього уведіть в поле нижче електронну пошту, яку Ви
                    вказували при заповненні анкети.
                </p>
                <Input
                    label='Електронна пошта'
                    value={email}
                    handler={emailHandler}
                    focusHandler={emailFocusHandler}
                    blurHandler={emailBlurHandler}
                    validationMsg={emailValidationMsg}
                    required={true}
                />

                <ButtonGroup position='center'>
                    <ReCAPTCHA sitekey='6LecyHspAAAAAHssfwag1_07ojfXDUEnp3uQvRWV' onChange={onChangeCaptcha} />
                </ButtonGroup>

                <ButtonGroup>
                    <Button content='Перевірити' handler={checkApplication} disabled={!(emailValidation && isCaptchaValid)} />
                </ButtonGroup>

                <div className='check-results'>
                    {results ? (
                        results.length === 0 ? (
                            <div className='check-results-not-found'>Результатів не знайдено, швидше заповнюйте анкету!</div>
                        ) : (
                            <div className='check-results-found'>
                                <h2>Знайдено анкет: {results.length}</h2>
                                {results.map((result, index) => {
                                    return (
                                        <p>
                                            №{index + 1}. Анкета з електронною поштою {result.email} від {parseDate(result.createdAt)}.
                                        </p>
                                    );
                                })}
                            </div>
                        )
                    ) : isLoading ? (
                        <ComponentLoader />
                    ) : (
                        <div className='check-results-note'>Тут з'являться результати пошуку</div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default CheckApplicationPage;
