import SERVER_URL from "../constans/server-url";

const API = {
    users: {
        readOne: SERVER_URL + "/api/user/read",
        readAll: SERVER_URL + "/api/user/read-all",
        updatePassword: SERVER_URL + "/api/user/update/password",
        updateUserProjectRole: SERVER_URL + "/api/user/update/project-role",
    },

    projectRoles: {
        readAll: SERVER_URL + "/api/project-role/read-all",
    },

    application: {
        create: SERVER_URL + "/api/application/create",
        readAll: SERVER_URL + "/api/application/read/all",
        readOneById: SERVER_URL + "/api/application/read/id",
        checkOneByEmail: SERVER_URL + "/api/application/check/email",
        exportApplications: SERVER_URL + "/api/application/export",
        deleteApplicationById: SERVER_URL + "/api/application/delete/id",
    },

    assessment: {
        readAssessmentStatistic: SERVER_URL + "/api/assessment/read/statistic",
        updateStageOneAssessment: SERVER_URL + "/api/assessment/update/stage-one/id",
        updateStageTwoAssessment: SERVER_URL + "/api/assessment/update/stage-two/id",
        updateStageThreeTeacherAssessment: SERVER_URL + "/api/assessment/update/teacher-stage-three/id",
        updateStageThreePsychologistAssessment: SERVER_URL + "/api/assessment/update/psychologist-stage-three/id",
        updateStageFourAssessment: SERVER_URL + "/api/assessment/update/stage-four/id",
    },
};

export default API;
