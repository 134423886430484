import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { usePDF, Margin } from "react-to-pdf";
import { Packer } from "docx";

//components
import PageMetadata from "../../components/PageMetadata/PageMetadata";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import ApplicationInformation from "../../components/ApplicationInformation/ApplicationInformation";
import AssessmentForm from "../../components/AssessmentForm/AssessmentForm";
import Button from "../../components/UI/Button/Button";

//states
import { selectAdminRole } from "../../features/authSlice";

//api
import API from "../../app/api";

//services
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import createApplicationWordFile from "../../features/createApplicationWordFile";

//styles
import "./style.scss";

//icons
import LeftArrowIcon from "../../assets/icons/left-arrow.svg";
import PageSubtitle from "../../components/UI/PageSubtitle/PageSubtitle";
import ButtonGroup from "../../components/UI/ButtonGroup/ButtonGroup";
import Modal from "../../components/UI/Modal/Modal";

//constants
const PAGE_TITLE = "Заявка";

const USER_ROLES_TITLES = {
    "Менеджер проекту": "project manager",
    "CEO Освіторії": "project director",
    "Директор школи": "school director",
    "Заступник директора школи": "school director deputy",
    "Вчитель Школи": "school teacher",
    "Психолог школи": "school psychologist",
    "Менеджер Школи": "school manager",
};

const ApplicationPage = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const isAdmin = useSelector(selectAdminRole);

    const [isLoading, setIsLoading] = useState(true);
    const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false);

    const [application, setApplication] = useState(undefined);

    const [userProjectRole, setUserProjectRole] = useState(AuthService.getProjectRoleName());
    const [printPageView, setPrintPageView] = useState(false);

    const { toPDF, targetRef } = usePDF({
        filename: `Заявка №${id}${application ? " " + application?.name : ""}.pdf`,
        page: { margin: Margin.MEDIUM },
    });

    useEffect(() => {
        id &&
            axios
                .post(API.application.readOneById, { id }, { headers: authHeader() })
                .then((response) => {
                    setApplication(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.response.status && [401, 403].includes(error.response.status)) {
                        AuthService.logout();
                        navigate("/signin");
                        window.location.reload();
                    } else {
                        navigate("/error", { state: { error: error.response.data.message } });
                    }
                });
    }, [navigate]);

    const userProjectRoleHandler = (value) => {
        setUserProjectRole(USER_ROLES_TITLES[value]);
    };

    const downloadPagePdf = () => {
        toPDF();
    };

    const downloadPageDocx = () => {
        const docx = createApplicationWordFile(application);
        Packer.toBlob(docx).then((blob) => {
            const date = new Date();
            const fileName = `Анкета ${application.name} (${date.toLocaleString("uk")}).docx`;
            const href = window.URL.createObjectURL(blob);

            const anchorElement = document.createElement("a");

            anchorElement.href = href;
            anchorElement.download = fileName;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        });
    };

    const printPageViewHandler = () => {
        setPrintPageView((prev) => {
            if (prev) {
                setUserProjectRole(AuthService.getProjectRoleName());
            } else {
                setUserProjectRole("project director");
            }
            return !prev;
        });
    };

    const deleteApplication = () => {
        id &&
            axios
                .post(API.application.deleteApplicationById, { id }, { headers: authHeader() })
                .then((response) => {
                    navigate("/applications");
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.response.status && [401, 403].includes(error.response.status)) {
                        AuthService.logout();
                        navigate("/signin");
                        window.location.reload();
                    } else {
                        navigate("/error", { state: { error: error.response.data.message } });
                    }
                });
    };

    const deletingModalHandler = () => {
        setIsDeletingModalOpen((prev) => !prev);
    };

    return (
        <>
            <PageMetadata title={`${PAGE_TITLE} №${id}`} />
            <main className='container'>
                <div className='page application-page'>
                    <PageTitle pageTitle={`Заявка №${id}`} />

                    <div className='application-page-control'>
                        <div>
                            <Link className='application-page-return' to='/applications'>
                                <img src={LeftArrowIcon} />
                                <span>До всіх заявок</span>
                            </Link>
                        </div>
                        <div>
                            {isAdmin && (
                                <>
                                    {printPageView && <Button content='Завантажити в PDF' handler={downloadPagePdf} highlighted={false} />}
                                    <Button content={printPageView ? "Звичайна версія" : "Версія для друку"} handler={printPageViewHandler} />
                                    <Button content='Завантижити DOCX' handler={downloadPageDocx} disabled={!application} />
                                </>
                            )}
                        </div>
                    </div>

                    {application && (
                        <div className='application-page-divider' ref={targetRef} data-print={printPageView}>
                            <ApplicationInformation application={application} userProjectRole={userProjectRole} printView={printPageView} />
                            <AssessmentForm
                                id={id}
                                applicationAssessment={application.assessment}
                                userProjectRole={userProjectRole}
                                userProjectRoleHandler={userProjectRoleHandler}
                                printView={printPageView}
                            />
                            {isAdmin && userProjectRole === "project manager" && !printPageView && (
                                <div className='application-page-deleting'>
                                    <PageSubtitle pageSubtitle='Видалення анкети' />
                                    <p>
                                        <span>Важливо!</span> Даний функціонал видаляє анкету та її оцінювання повністю та остаточно. Використовуйте
                                        його лише за крайньої необхідності. Функціонал доступний тільки адміністраторам сайту з проєктною роллю
                                        "Менеджер проєкту".
                                    </p>

                                    <ButtonGroup position='end'>
                                        <Button content='Видалити анкету' handler={deletingModalHandler} />
                                    </ButtonGroup>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </main>
            {isLoading && <Loader />}
            {isDeletingModalOpen && (
                <Modal
                    title={`Ви впевнені, що хочете видалити анкету ${application.name}?`}
                    message='Ця дія призведе до безповоротного видалення даних анкети та оцінювання із системи.'
                    closeHandler={deletingModalHandler}
                    handler={deleteApplication}
                    buttonText='Видалити'
                />
            )}
        </>
    );
};

export default ApplicationPage;
