import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//componets
import StageTitle from "./StageTitle";
import Button from "../../UI/Button/Button";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import Input from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";

//constants
import ASSESSMENTS from "../../../constans/applicationAssessments";
import API from "../../../app/api";
import AuthService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

const StageTwoAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const allowedEditorRoles = ["project manager", "school director deputy"];
    const allowedViewerRoles = [
        "project manager",
        "project director",
        "school director",
        "school director deputy",
        "school teacher",
        "school psychologist",
        "school manager",
    ];

    const points = applicationAssessment?.stageTwoPoints;
    const assessment = applicationAssessment?.stageTwoAssessment;

    const [stageTwoPoints, setStageTwoPoints] = useState(Number.isInteger(points) ? points : "");
    const [stageTwoAssessment, setStageTwoAssessment] = useState(assessment || "");

    const stageTwoPointsHandler = (value) => {
        setStageTwoPoints(value);
    };

    const stageTwoAssessmentHandler = (value) => {
        setStageTwoAssessment(value);
    };

    const updateStageTwo = () => {
        axios
            .post(API.assessment.updateStageTwoAssessment, { id, stageTwoPoints, stageTwoAssessment }, { headers: authHeader() })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    if (applicationAssessment?.stageOneAssessment === "рекомендовано") {
        return (
            <div className='stage'>
                <StageTitle title='Етап 2. Оцінювання заступником директора Школи' />

                {allowedViewerRoles.includes(userRole) &&
                    (Number.isInteger(points) && assessment ? (
                        <div className='stage-rated' data-assessment={assessment}>
                            <p className='stage-rated-points'>{points}</p>
                            <p className='stage-rated-assessment'>{assessment}</p>
                        </div>
                    ) : (
                        <div className='stage-not-rated'>
                            <p>Не оцінено</p>
                        </div>
                    ))}

                {allowedEditorRoles.includes(userRole) && (
                    <div className='stage-form'>
                        <Input label='Кількість балів' value={stageTwoPoints} handler={stageTwoPointsHandler} />
                        <Select label='Фінальна оцінка' options={ASSESSMENTS} value={stageTwoAssessment} handler={stageTwoAssessmentHandler} />

                        <ButtonGroup>
                            <Button content='Зберегти' handler={updateStageTwo} disabled={!(stageTwoPoints && stageTwoAssessment)} />
                        </ButtonGroup>
                    </div>
                )}

                {!allowedViewerRoles.includes(userRole) && !allowedEditorRoles.includes(userRole) && (
                    <p className='stage-note'>У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}
            </div>
        );
    }

    return (
        <div className='stage'>
            <StageTitle title='Етап 2. Оцінювання заступником директора Школи' />
            <p className='stage-note'>Анкета не отримала рекомендації на попередньому етапі.</p>
        </div>
    );
};

export default StageTwoAssessment;
