import React from "react";

import "./style.scss";

export default function Pagination({ page, pages, handler }) {
    if (pages.length === 1) {
        return <></>;
    } else if (pages.length < 8) {
        return (
            <div className='pagination'>
                {pages.map((pageA, index) => {
                    return (
                        <div className={index === page - 1 ? "active" : ""} onClick={() => handler(index + 1)} key={index}>
                            {index + 1}
                        </div>
                    );
                })}
            </div>
        );
    } else if (page < 5 || page > pages.length - 4) {
        return (
            <div className='pagination'>
                {pages.slice(0, 5).map((pageA, index) => {
                    return (
                        <div className={pageA === page - 1 ? "active" : ""} onClick={() => handler(pageA + 1)} key={index}>
                            {pageA + 1}
                        </div>
                    );
                })}
                <div>...</div>
                {pages.slice(-5).map((pageA, index) => {
                    return (
                        <div className={pageA === page - 1 ? "active" : ""} onClick={() => handler(pageA + 1)} key={index}>
                            {pageA + 1}
                        </div>
                    );
                })}
            </div>
        );
    } else if (page > 4 && page < pages.length - 3) {
        return (
            <div className='pagination'>
                <div className={page === 1 ? "active" : ""} onClick={() => handler(1)}>
                    1
                </div>
                <div>...</div>
                {pages.slice(page - 3, page + 2).map((pageA, index) => {
                    return (
                        <div className={pageA === page - 1 ? "active" : ""} onClick={() => handler(pageA + 1)} key={index}>
                            {pageA + 1}
                        </div>
                    );
                })}
                <div>...</div>
                <div className={page === pages.length ? "active" : ""} onClick={() => handler(pages.length)}>
                    {pages.length}
                </div>
            </div>
        );
    } else {
        return <div>{page}</div>;
    }
}
