import './style.scss';

export default function Checkbox({ label, value, handler }) {
    return (
        <div className='custom-checkbox'>
            <label className='custom-checkbox-label'>
                <input
                    className='custom-checkbox-input'
                    type='checkbox'
                    checked={value}
                    onChange={handler}
                />
                <span className='checkmark'></span>
                {label}
            </label>
        </div>
    );
}
