const formLabels = {
    name: "Вкажіть Ваше прізвище, ім’я та по батькові",
    birthdayDate: "Вкажіть Вашу дату народження",
    informationSource: "Звідки Ви дізнались про Стипендіальну програму",
    phoneNumber: "Вкажіть Ваш номер мобільного телефону (+380502222222) або Telegram (@stypendiat)",
    email: "Вкажіть, будь ласка, Вашу електронну пошту",
    futureGrade: "У якому класі Ви будете навчатись у наступному 2024/2025 навчальному році?",
    educationalInstitution: "У якому навчальному закладі Ви навчаєтесь у 2023/2024 навчальному році (зараз)?",
    settlement: "З якого Ви міста/села та області?",
    socialMediaLink: "Надайте, будь ласка, посилання на Ваш профіль у соціальних мережах (Facebook, Instagram, TikTok тощо)",
    photoLink: "Надайте посилання на Ваше фото (попередньо перевірте, будь ласка, налаштування доступу до файлу)",
    academicAchievements:
        "Якщо Ви переможець/переможниця в предметних олімпіадах, конкурсах МАН, маєте публікації або винаходи тощо, вкажіть, будь ласка",
    lastYearReportCardPhotoLink: "Надайте посилання на фото Вашого табеля минулого навчального року",
    currentYearFirstSemesterReportCardPhotoLink: "Надайте посилання на фото Вашого табеля 1 семестру цього навчального року",
    sportsAndCreativeAchievements:
        "Якщо Ви професійно займаєтесь певним видом спорту або маєте кваліфікацію у певному виді творчості — розкажіть про це, будь ласка. Поділіться своїми творчими та/або спортивними досягненнями, якими Ви пишаєтеся найбільше",
    socialActivity:
        "Якщо Ви брали участь у соціальній діяльності, у благодійних акціях, чи активну участь у шкільному житті або волонтерили, розкажіть, будь ласка, про це",
    motivation: "Чому Ви хочете отримати грант на навчання саме у Новопечерській школі?",
    videoLink: "Надайте, будь ласка, посилання на відео",
    aboutFamily: "Розкажіть про Вашу сім'ю (як ви проводите час разом, чи маєте особливі сімейні традиції тощо?)",
    aboutApproachingVictory: "Що Ви разом спільно з родиною робите для наближення нашої Перемоги?",
    contactPersonName: "ПІБ одного з батьків або контактної особи",
    contactPersonEmail: "Електронна пошта одного з батьків або контактної особи",
    contactPersonPhone: "Номер телефону одного з батьків або контактної особи",
    parentsSocialMediaLink: "Поділіться, будь ласка, посиланнями на профілі батьків або контактної особи у соціальних мережах",
    essay: "У вільній формі опишіть, будь ласка, Ваш досвід командної роботи та розкрийте важливість об’єднання зусиль для досягнення кращих результатів. Ми гарантуємо конфіденційність усієї наданої інформації. Орієнтуйтесь на обсяг до 3000 символів.",
    isConfirmed: "Я підтверджую свою фізичну присутність на етапі проходження конкурсних випробувань.",
    isAllowedDataProcessing: "Я даю згоду на обробку персональних даних.",
    isAcceptedTerms:
        "Я усвідомлюю, що  Стипендіальна програма - це соціальний проєкт для здібних дітей з родин з рівнем достатку низьким або нижче середнього, а також підтримка родинам, що опинились в складних життєвих обставинах внаслідок повномасштабного воєнного вторгнення. Ви підтверджуєте, що Ваша родина відповідає одному з критеріїв та готові надати, за необхідності, підтверджуючі документи.",
};

module.exports = formLabels;
