import { useSelector } from "react-redux";

//states
import { selectAdminRole } from "../../features/authSlice";

//components
import PageSubtitle from "../UI/PageSubtitle/PageSubtitle";
import Select from "../UI/Select/Select";
import StageOneAssessment from "./components/StageOneAssessment";
import StageTwoAssessment from "./components/StageTwoAssessment";
import StageThreeAssessment from "./components/StageThreeAssessment";
import StageFourAssessment from "./components/StageFourAssessment";

//styles
import "./style.scss";

//constants
const USER_ROLES = [
    "Менеджер проекту",
    "CEO Освіторії",
    "Директор школи",
    "Заступник директора школи",
    "Вчитель Школи",
    "Психолог школи",
    "Менеджер Школи",
];

const USER_ROLES_TITLES = {
    "project manager": "Менеджер проекту",
    "project director": "CEO Освіторії",
    "school director": "Директор школи",
    "school director deputy": "Заступник директора школи",
    "school teacher": "Вчитель Школи",
    "school psychologist": "Психолог школи",
    "school manager": "Менеджер Школи",
};

const AssessmentForm = ({ id, applicationAssessment, userProjectRole, userProjectRoleHandler, printView }) => {
    const isAdmin = useSelector(selectAdminRole);

    return (
        <div className='assessment-form'>
            <PageSubtitle pageSubtitle='Оцінювання' />
            {isAdmin && !printView && (
                <div className='assessment-form-role-simulator'>
                    <Select
                        label='Перглянути оцінювання як'
                        value={USER_ROLES_TITLES[userProjectRole]}
                        options={USER_ROLES}
                        handler={userProjectRoleHandler}
                    />
                </div>
            )}
            <StageOneAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
            <StageTwoAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
            <StageThreeAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
            <StageFourAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
        </div>
    );
};

export default AssessmentForm;
