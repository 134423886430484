import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//styles
import "./style.scss";

export default function Button({ handler, content, disabled = false, highlighted = true, type = "button", link }) {
    if (type === "button") {
        return (
            <button className='client-button' onClick={handler} data-disabled={disabled} disabled={disabled} data-highlighted={highlighted}>
                {content}
            </button>
        );
    } else if (type === "link") {
        return (
            <Link className='client-button' to={link} data-disabled={disabled} disabled={disabled} data-highlighted={highlighted}>
                {content}
            </Link>
        );
    } else if (type === "anchor") {
        return (
            <HashLink className='client-button' to={link} data-disabled={disabled} disabled={disabled} data-highlighted={highlighted}>
                {content}
            </HashLink>
        );
    }

    return <></>;
}
