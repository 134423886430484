import { useState } from "react";
import "./style.scss";
import closeIcon from "../../../assets/icons/close.svg";

export default function SearchInput({
    label,
    name,
    type = "text",
    value,
    handler,
    blurHandler = undefined,
    focusHandler = undefined,
    required,
    disabled = false,
    validationMsg = undefined,
    maxLength,
}) {
    const [isFocused, setIsFocused] = useState(false);

    const inputHandler = (e) => {
        const { value } = e.target;

        if (maxLength && value.length > maxLength) {
            return;
        } else {
            handler(value);
        }
    };

    const clearHandler = () => {
        handler("");
    };

    const focus = (e) => {
        setIsFocused(true);
        if (focusHandler) {
            focusHandler(e.target.value);
        }
    };

    const blur = (e) => {
        setIsFocused(false);
        if (blurHandler) {
            blurHandler(e.target.value);
        }
    };

    return (
        <>
            <div
                className={`search-input ${disabled ? "search-input-disabled" : ""} ${isFocused ? "input-focused" : ""}`}
                data-validation={validationMsg ? false : true}
            >
                <label className='search-input-label input-label-focus'>
                    {label}
                    {required && <span className='search-input-label-required'> *</span>}
                    <input type={type} name={name} value={value} onChange={inputHandler} onFocus={focus} onBlur={blur} disabled={disabled} />
                    <span className='search-input-clear'>
                        <button onClick={clearHandler}>
                            <img src={closeIcon} alt='close icon' />
                        </button>
                    </span>
                </label>
            </div>

            <div className='search-input-validation-text'>{validationMsg}</div>
        </>
    );
}
