import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//states
import { selectAdminRole } from '../../features/authSlice';

//validation
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail } from 'validator';

import AuthService from '../../services/auth.service';

//styles
import './style.scss';
import PageTitle from '../../components/PageTitle/PageTitle';

const PAGE_TITLE = 'Ствоерння нового користувача';

const required = (value) => {
    if (!value) {
        return (
            <div className='signup-card-form-group-alert' role='alert'>
                Це поле обов'язкове!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className='signup-card-form-group-alert' role='alert'>
                Невірний формат електронної адреси!
            </div>
        );
    }
};

const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className='signup-card-form-group-alert' role='alert'>
                Логін повинен бути від 3 до 20 символів!
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className='signup-card-form-group-alert' role='alert'>
                Пароль повинен бути від 6 до 40 символів!
            </div>
        );
    }
};

export default function SignupPage() {
    const navigate = useNavigate();

    const form = useRef();
    const checkBtn = useRef();

    const hasAdminRole = useSelector(selectAdminRole);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState(['user']);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState('');

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeRole = (e) => {
        const role = e.target.value;
        switch (role) {
            case 'user':
                setRoles(['user']);
                break;
            case 'moderator':
                setRoles(['user', 'moderator']);
                break;
            case 'admin':
                setRoles(['user', 'moderator', 'admin']);
                break;
            default:
                setRoles(['user']);
        }
    };

    const handleRegister = (e) => {
        e.preventDefault();

        setMessage('');
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.register(username, email, password, roles).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    navigate('/users');
                },
                (error) => {
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <main className='container'>
            <div className='page signup'>
                <div>
                    <PageTitle pageTitle={PAGE_TITLE} />
                </div>

                <Form onSubmit={handleRegister} ref={form}>
                    {!successful && (
                        <div>
                            <div className='signup-card-form-group'>
                                <label className='signup-card-form-group-label' htmlFor='username'>
                                    Логін <span>*</span>
                                </label>
                                <Input
                                    type='text'
                                    className='signup-card-form-group-input'
                                    name='username'
                                    value={username}
                                    onChange={onChangeUsername}
                                    validations={[required, vusername]}
                                />
                            </div>

                            <div className='signup-card-form-group'>
                                <label className='signup-card-form-group-label' htmlFor='email'>
                                    Електронна пошта <span>*</span>
                                </label>
                                <Input
                                    type='text'
                                    className='signup-card-form-group-input'
                                    name='email'
                                    value={email}
                                    onChange={onChangeEmail}
                                    validations={[required, validEmail]}
                                />
                            </div>

                            <div className='signup-card-form-group'>
                                <label className='signup-card-form-group-label' htmlFor='password'>
                                    Пароль <span>*</span>
                                </label>
                                <Input
                                    type='password'
                                    className='signup-card-form-group-input'
                                    name='password'
                                    value={password}
                                    onChange={onChangePassword}
                                    validations={[required, vpassword]}
                                />
                            </div>

                            <div className='signup-card-form-group'>
                                <label className='signup-card-form-group-label'>
                                    Роль <span>*</span>
                                </label>
                                <div className='signup-card-form-group-radio' onChange={onChangeRole}>
                                    <label className='label-container'>
                                        Користувач
                                        <input type='radio' name='role' value='user' defaultChecked />
                                        <span className='checkmark'></span>
                                    </label>
                                    <label className='label-container'>
                                        Модератор
                                        <input type='radio' name='role' value='moderator' />
                                        <span className='checkmark'></span>
                                    </label>
                                    <label className='label-container'>
                                        Адміністратор
                                        <input type='radio' name='role' value='admin' />
                                        <span className='checkmark'></span>
                                    </label>
                                </div>
                            </div>

                            {hasAdminRole ? (
                                <div className='signup-card-form-group'>
                                    <button className='signup-card-form-group-button'>Зареєcтрувати</button>
                                </div>
                            ) : (
                                <div className=''>У вас немає доступу до створення нових користувачів</div>
                            )}
                        </div>
                    )}

                    {message && (
                        <div className='form-group'>
                            <div className={successful ? 'alert alert-success' : 'alert alert-danger'} role='alert'>
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                </Form>
            </div>
        </main>
    );
}
