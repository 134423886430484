import React, { useState } from "react";

//components
import Button from "../UI/client/Button/Button";

//styles
import "./style.scss";

//images
import eyesIllustration from "../../assets/img/illustrations/eyes-illustration.svg";
import attentionIllustration from "../../assets/img/illustrations/attention-illustration.svg";
import asteriskIcon from "../../assets/icons/asterisk.svg";
import titleArrowIllustration from "../../assets/img/illustrations/title-arrow.svg";
import actionsIllustration from "../../assets/img/illustrations/actions-illustration.svg";
import actionsMobileIllustration from "../../assets/img/illustrations/actions-illustration-mobile.svg";
import personPhoto from "../../assets/img/photo/person-photo.png";

const LandingInformation = ({ nowDate, endDate }) => {
    return (
        <div>
            <div className='hero'>
                <div className='hero-content'>
                    <div className='hero-content-text'>
                        <h1 className='hero-content-text-title'>Стипендіальна програма</h1>

                        {nowDate < endDate ? (
                            <p className='hero-content-text-description'>Відкритий прийом заявок на безоплатне навчання</p>
                        ) : (
                            <p className='hero-content-text-description'>Прийом заявок в 2024 році завершено!</p>
                        )}
                    </div>
                    <div className='hero-content-action'>
                        {nowDate < endDate ? (
                            <Button content='Подати заявку' type='anchor' link='#application' />
                        ) : (
                            <Button content='Подати заявку' type='button' disabled={true} />
                        )}
                    </div>
                </div>
                <div className='hero-illustration'></div>
            </div>

            <div className='about'>
                <h2 className='about-title'>
                    <span>Що таке</span>
                    <span className='about-title-highlighted'>стипендіальна програма?</span>
                </h2>
                <div className='about-description'>
                    <h3 className='about-description-title'>
                        Стипендіальна програма — це грант від громадської спілки «Освіторія» та Новопечерської школи на безоплатне навчання.
                    </h3>
                    <p className='about-description-paragraph'>
                        Його мета — надати можливість талановитим учням та ученицям 8-11 класів здобувати якісну повну середню освіту, розвивати
                        лідерські навички в одній з найбільш інноваційних шкіл України та світу — Новопечерській школі.
                    </p>

                    <p className='about-description-paragraph'>
                        Грант повністю покриває вартість навчання в Новопечерській школі у Києві для родин із низьким рівнем доходу або нижче
                        середнього. В умовах повномасштабної війни гранти також надаються учням та ученицям із родин, які опинилися в складних
                        життєвих обставинах через російське воєнне вторгнення.
                    </p>
                    <p className='about-description-paragraph'>
                        Стипендіальна програма приймає дітей з усієї країни. На час навчання стипендіати з інших міст перебуватимуть під наглядом
                        наставника. Школярі забезпечуються житлом, харчуванням та усім необхідним для навчання.
                    </p>
                </div>
            </div>

            <div className='conditions'>
                <div className='conditions-card'>
                    <img className='conditions-card-illustration' src={attentionIllustration} alt='card-illustration' />
                    <div className='conditions-card-content'>
                        <h3 className='conditions-card-content-title'>
                            Які <span>обовʼязкові умови</span> для участі
                            <img src={titleArrowIllustration} />
                        </h3>

                        <ul>
                            <li>бути учнем/ученицею 8-11 класу у наступному 2024/2025 навчальному році;</li>
                            <li>мати визначні академічні, творчі, спортивні здобутки;</li>
                            <li>​бути проактивним/-ою, ініціативним/-ою та відповідальним/-ою;</li>
                            <li>належати до родини з низьким рівнем доходу або нижче середнього.</li>
                        </ul>
                    </div>
                    <div className='conditions-card-note'>
                        <img src={asteriskIcon} />
                        <p>
                            <span>
                                Соціальні стипендії також надаються учням з родин, які опинилися в складних життєвих обставинах через повномасштабне
                                воєнне вторгнення росії.
                            </span>
                        </p>
                    </div>
                </div>
                <div className='conditions-card'>
                    <img className='conditions-card-illustration' src={eyesIllustration} alt='card-illustration' />
                    <div className='conditions-card-content'>
                        <h3 className='conditions-card-content-title'>
                            Як <span>отримати грант</span> на навчання
                            <img src={titleArrowIllustration} />
                        </h3>

                        <ul>
                            <li>​заповнити онлайн-форму до 19 квітня 2024 року;</li>
                            <li>
                                успішно скласти вступні випробування: тести з математики, української та англійської мови і участь у командній роботі (15 - 16 травня);
                            </li>
                            <li>пройти співбесіду з родиною.</li>
                        </ul>
                    </div>

                    <div className='conditions-card-note'>
                        <img src={asteriskIcon} />
                        <p>
                            Також можна рекомендувати учнів на отримання гранту на навчання в Новопечерській школі. Для цього потрібно заповнити
                            онлайн-форму, а куратор програми зв’яжеться з рекомендованими учнями та розповість про деталі.
                        </p>
                    </div>
                </div>
            </div>

            <div className='actions'>
                <div className='actions-title'>
                    Стипендіальна програма
                    <br />
                    2024
                </div>
                <div className='actions-illustration'>
                    <img className='actions-illustration-desktop' src={actionsIllustration} />
                    <img className='actions-illustration-mobile' src={actionsMobileIllustration} />
                </div>
                <div className='actions-buttons'>
                    {nowDate < endDate ? (
                        <>
                            <Button content='Подати заявку' type='anchor' link='#application' />
                            <Button content='Рекомендувати' type='link' link='https://forms.gle/Hm2P7tUDN3DoMqTa8' />
                        </>
                    ) : (
                        <>
                            <Button content='Подати заявку' type='button' disabled={true} />
                            <Button content='Рекомендувати' type='button' disabled={true} />
                        </>
                    )}
                </div>
            </div>

            <div className='quote'>
                <div className='quote-person'>
                    <img src={personPhoto} />
                    <div>
                        <h4>Зоя Литвин</h4>
                        <p>Голова ГС «Освіторія»</p>
                        <p>Засновниця Новопечерської школи</p>
                    </div>
                </div>

                <div className='quote-content'>
                    <h3 className='quote-content-title'>«Я переконана, що освіта створює "завтра" для нашої країни.</h3>
                    <p className='quote-content-text'>
                        Тож всі наші проєкти спрямовані на те, щоб якомога більше дітей мали доступ до різних і рівних можливостей для здобуття
                        якісної освіти. Стипендіальна програма розрахована на талановитих і здібних дітей, майбутніх лідерів, які мають неосяжні мрії
                        та готові працювати для їх здійснення. В умовах повномасштабного воєнного вторгнення ми також надаємо гранти на навчання дітям
                        із родин, які постраждали та опинилися в скрутних життєвих обставинах. Це наш спосіб підтримати українські родини у такі
                        непрості часи. Ми пишаємося кожним із наших стипендіатів і з нетерпінням чекаємо зустрічі з амбітними учасниками програми
                        нового навчального року».
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LandingInformation;
